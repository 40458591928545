import React from 'react';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Article from 'components/help/helpArticle';
import video from 'video/mobile-cursor.webm';
import video1 from 'video/mobile-cursor.mp4';

const Video = styled.video`
  max-width: 95%;
  ${media.desktop} {
    max-width: 100%;
  }
`;

export const Content = () => {
  return (
    <div>
      <p>As of now, native mobile apps are not supported.</p>
      <p>
        LiveSession supports web pages on mobile browsers and WebView applications. We track typical
        mobile events, such as touch and touchmove. We also display a different pointer on mobile
        devices, a dot like in the example below:
      </p>
      <Video width="100%" height="100%" autoPlay muted loop className="mt-5">
        <source src={video} type="video/webm" />
        <source src={video1} type="video/mp4" />
      </Video>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
];

export const frontmatter = {
  metaTitle: 'Does LiveSession work with mobile apps?',
  metaDescription: 'Check how you can track users in mobile apps.',
  canonical: '/help/work-with-mobile-apps/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
